<template>
	
	<div class="meritWrap01">
      <div class="innerWrap">
        <div class="meritDiv">
          <h3>전반적인 <br>아파트 관리를 위한</h3>
          <ul>
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon1.png" alt="서브아이콘"></div>
              <p>장기수선계획 및 <br> 시설물 이력관리</p>
            </li>
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon2.png" alt="서브아이콘"></div>
              <p>민원 통계 등<br>데이터 통계</p>
            </li>
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon3.png" alt="서브아이콘"></div>
              <p>관리비 체납통제 및<br>입주민 주차 관리</p>
            </li>
          </ul>
        </div>
      </div>
		</div>
    <div class="meritWrap01">
      <div class="innerWrap">
        <div class="meritDiv">
          <h3 class="ar">업무처리<br>보다 신속하게</h3>
          <ul class="ar">
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon4.png" alt="서브아이콘"></div>
              <p>신속한<br>안내방송 진행</p>
            </li>
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon5.png" alt="서브아이콘"></div>
              <p>법정달력 및 <br>업무달력</p>
            </li>
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon6.png" alt="서브아이콘"></div>
              <p>민원 접수 및 <br>이력관리</p>
            </li>
          </ul>
        </div>
      </div>
		</div>
    <div class="meritWrap01">
      <div class="innerWrap">
        <div class="meritDiv">
          <h3>수많은 문서들의<br>분류와 관리가 편해집니다</h3>
          <ul>
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon7.png" alt="서브아이콘"></div>
              <p>아파트 문서양식<br><span class="mOff">폴더별로</span> 수십종 제공</p>
            </li>
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon8.png" alt="서브아이콘"></div>
              <p>친절한<br>문서검색기능</p>
            </li>
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon9.png" alt="서브아이콘"></div>
              <p>영구보관되는<br>전자결재문서</p>
            </li>
          </ul>
        </div>
      </div>
		</div>
    <div class="meritWrap01">
      <div class="innerWrap">
        <div class="meritDiv">
          <h3 class="ar">현장에서<br>바로바로 보고하세요</h3>
          <ul class="ar twoItem">
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon10.png" alt="서브아이콘"></div>
              <p>사진을 찍어<br>바로 업무보고</p>
            </li>
            <li>
              <div class="imgWrap03"><img src="img/n_main/icon11.png" alt="서브아이콘"></div>
              <p>간편한<br>일일보고 전송</p>
            </li>
          </ul>
        </div>
      </div>
	</div>
	
</template>

<script>

export default {
  name: 'Merit',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
